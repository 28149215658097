import React, { useState } from "react";
import clock from "./clock.svg";
import "./App.css";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import { differenceInMilliseconds, format, addMilliseconds } from "date-fns";

const App = () => {
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [calculations, setCalculations] = useState({
    duration: "--:--",
    shortTime: "--:--",
    overtime: "--:--",
    closingTime: "--:--",
  });

  const handleDateChange = (event) => {
    setSelectedTime(event);
  };

  const timeConvert = (num) => {
    let hours = Math.floor(num / 60 / 60 / 1000);
    hours = hours.toString().length > 1 ? hours : "0" + hours.toString();
    let minutes = Math.floor(num / 60 / 1000) % 60;
    minutes =
      minutes.toString().length > 1 ? minutes : "0" + minutes.toString();

    return `${hours}:${minutes}`;
  };

  const calculateWorkingTime = (time) => {
    const now = new Date();
    const timeDiff = differenceInMilliseconds(now, time);
    const millisecondsShortWorkingDay = 6 * 60 * 60 * 1000; // 6 hours
    let millisecondsWorkingDay = 8 * 60 * 60 * 1000; // 8 hours
    const millisecondsPause = 45 * 60 * 1000; // 45 minutes

    if (timeDiff >= millisecondsShortWorkingDay) {
      millisecondsWorkingDay += millisecondsPause;
    }

    const duration = timeConvert(
      timeDiff < millisecondsShortWorkingDay ? timeDiff : timeDiff - millisecondsPause
    );
    const shortTime =
      timeDiff < millisecondsWorkingDay
        ? timeConvert(millisecondsWorkingDay - timeDiff)
        : "--:--";
    const overtime =
      timeDiff >= millisecondsWorkingDay
        ? timeConvert(timeDiff - millisecondsWorkingDay)
        : "--:--";
    const closingTime = addMilliseconds(
      time,
      timeDiff < millisecondsShortWorkingDay
        ? millisecondsWorkingDay + millisecondsPause
        : millisecondsWorkingDay
    );

    setCalculations({
      duration,
      shortTime,
      overtime,
      closingTime: format(closingTime, "HH:mm"),
    });
  };

  return (
    <div className="App-root">
      <img src={clock} className="App-logo" alt="logo" />
      <h1>Working Time Calculator</h1>
      <p>Select your Start Time:</p>
      <div className="App-calculations">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
          <TimePicker
            value={selectedTime}
            onChange={handleDateChange}
            autoOk
            ampm={false}
            showTodayButton
            todayLabel="now"
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={() => calculateWorkingTime(selectedTime)}
        >
          Calculate
        </Button>
      </div>
      <p>Duration: {calculations.duration} ⏳</p>
      <p className="App-calculations-short-time">
        Short Time: {calculations.shortTime} 👎
      </p>
      <p className="App-calculations-overtime">
        Overtime: {calculations.overtime} 👍
      </p>
      <p>Closing Time: {calculations.closingTime} 🏁</p>
    </div>
  );
};

export default App;
